import "bootstrap";
import "../scss/style.scss";
import "../fontawesome/css/all.min.css";

import $ from "jquery";

// Example jQuery usage
jQuery(function () {
  $('button[data-bs-toggle="collapse"]').on("click", function () {
    var chevron = $(this).find(".fa-chevron-down"); // Find the chevron icon

    // Toggle the 'expanded' and 'collapsed' classes
    chevron.toggleClass("expanded collapsed");
  });
});
